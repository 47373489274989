/* Sample styling. Modify as per your design preferences. */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.landing, .about, .articles, .research {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

h1, h2, h3 {
  color: #333;
}

p {
  color: #666;
}
